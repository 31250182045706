@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&family=Space+Grotesk:wght@700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,400;0,500;0,600;1,400&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@400;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

:root {
    --mainColor: #491430;
    --mastColor: rgb(246, 132, 34);

    --neutralColor: #ffffff;
    --accentColor1: #1102EB;
    --accentColor2: #610197;
      --accentColor3: #b3aa73;
    --accentColor4: rgba(113, 149, 149, .5);
      --accentColor5: rgba(16, 34, 38, .8);
    --accentColor6: rgba(0, 0, 0, .4);
        --accentColorzero: rgba(246, 132, 34, 0.0);
    --gradientColor1: #c72065;
        --gradientColor2: #0a1318;
    --neonColor1: #cad305;
     --neonColor2: #019712;
      --neonColor3: #610197;
      --neonColor4: #1102EB;
        --fs-xl: clamp(3.0rem, 3.5vw, 4rem);
    
         --fs-l: clamp(1.75rem, 2.5vw, 2.75rem);
    
    --fs-p: clamp(.875rem, 5.25vw, 1.20rem);
 
    
    
    --navbar_height: 80px;
    scroll-padding-top: calc(var(--navbar_height) + 0px);
    scroll-behavior: smooth;

 
}

.body-landing {
    overflow-x: hidden !important;
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 400 !important;
    font-style: normal !important;
    text-rendering: optimize legibility !important;
    margin: 0 !important;
    padding: 0 !important;
    line-height: calc(2vw + 1rem) !important;
   
}



.even-columns .col {
    padding: 0em;
    background: rgba(0,0,0,.1);
   /* overflow: hidden;*/
    /*border: 3px #000000 solid;*/
}

.even-columns .col img {
    padding: 0em;
  object-fit: fill;
    object-position: bottom;
    width: 100%;
    border: 0px #000000 solid;
}

.even-columns {
    display: flex;
}

even-columns > * {
    flex-basis: 100%;
}

#hero {
    border-bottom: 5px #ffffff solid;
        min-height: clamp(200px, 25vh, 450px);
    background: linear-gradient(
            rgba(0, 00, 0, 0.0),
            rgba(0, 0, 0, 0.0)), center center ;
      background-size: cover;
                    object-fit: fill;
}



#hero_short {
    border-bottom: 5px #ffffff solid;
       min-height: clamp(200px, 15vh, 450px);
    background: linear-gradient(
            rgba(0, 00, 0, 0.0),
            rgba(0, 0, 0, 0.0)), center center ;
      background-size: cover;
                    object-fit: fit;
}

.hero_img {
    width: 50%; 
}
.display-4 {
    text-transform: uppercase;
    /*color: #412304;*/
    color: #00aeef;
    text-rendering: optimize legibility;
}


.display-5 {
 
    color: var(--mastColor);
    text-rendering: optimize legibility;
    
}

.display-6 {
     font-size: var(--fs-xl);
}

.display-7 {
    color: var(--mainColor);
     font-size: var(--fs-l);
     line-height: 1.75;

}

.balanced {
    text-wrap: balance;
}

.h1Landing,
.h2Landing,
.h3Landing {
    /*text-transform: uppercase;*/
font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

.p2 {
    font-size:var(--fs-p);
}

.carousel .carousel-caption {
    background: rgba(0, 0, 0, .5);
    padding: 1rem 1rem;
    width: 70vw;
    margin: 0 auto;
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 25%;
    left: 15%;
    z-index: 10;

    color: #fff;
    text-align: center;
}

.carousel a {
    background: rgba(0, 0, 0, 1);
  color: var(--mastColor);

}

/* OVERLAYS and BACKGROUNDS*/
.bg-black {
    background: black;
    opacity: .5;
}

.bg-black3 {
    background: rgba(0, 0, 0, .5);
    border: 2px solid var(--accentColor2);
    filter: drop-shadow(0 0 10px #000000);
}

.bg-black4 {
    background: rgba(0, 0, 0, .8);
}

.bg-black5 {
    background: linear-gradient(to right,
            rgba(0, 0, 0, 0.1),
            rgba(0, 0, 0, 0.9)), #272727;
}

.bg-black9carousel {
    background: linear-gradient(to right,
            rgba(0, 0, 0, 0.7),
            rgba(0, 0, 0, 0.9)), #272727;
}

.bg-callout {
    background: linear-gradient(rgba(0, 0, 0, .5),
            rgba(0, 0, 0, .5));
    color: #ffffff;
}

.bg-callout2 {
    background: linear-gradient(rgba(255, 255, 255, .2),
            rgba(255, 255, 255, .2));
    color: #ffffff;
}

.bg-electric {
    background: linear-gradient(135deg,
        var(--accentColor6),   

           var(--accentColor6),
        var(--neonColor4),
            var(--neonColor3),
            var(--accentColor6),
       var(--accentColor6));
}
r
.bg-motherearth {
    background: linear-gradient(to right,
           var(--neonColor1),
            var(--neonColor2));
}


.bg-grey {
    background: red;
}

.bg-nuarca {
    background: #00aeef;
}

.bg-nuarca2 {
    background: rgba(0, 174, 239, 0.2);
}

.bg-white1 {
    background: rgba(255, 255, 255, .5);
    border: 1px solid rgba(255, 255, 255, 1);
}

.bg-orange1 {
    background: rgba(243, 112, 33, .9);
    border: 1px solid rgba(255, 255, 255, 1);
}

.btn-nuarca {
    color: #fff;
    background-color: transparent;
    background-image: none;
    border-color: #f68422;
}

.btn-nuarca:hover {
    color: #fff !important;
    background-color: #f37021 !important;
}

.btn-nuarca2 {
    color: #000;
    background-color: transparent;
    background-image: none;
    border-color: #f68422;
}

.btn-nuarca2:hover {
    color: #fff;
    background-color: #f37021;
}

.btn-contact {
    color: #000;
    background-color: transparent;
    background-image: none;
    border-color: #ed1c24;
}

.btn-contact:hover {
    color: #fff;
    background-color: #ed1c24;
}

.dark-overlay {
    background: rgba(0, 0, 0, .4);
}

.light-overlay {
    background: rgba(0, 206, 206, 0.9);
}

/* misc */

.about-img {
    margin-top: -50px;
}


.blockquote {
    text-transform: uppercase;
    font-size:var(--fs-p);
    border-left: 5px #f37021 solid;
    padding-left: 1rem;
    padding-bottom: .5rem;
}

#blockchain p {
    line-height: 1.75;
}

.shrink img {
    max-height: 8rem;
    object-fit: scale-down;
}

.fas {
    margin-bottom: 2rem;
    color: #ffffff;
}

.fas:hover {
    opacity: 0.7;
}

.fab {
    margin-bottom: 2rem;
    color: #ffffff;
}

.fab:hover {
    opacity: 0.7;
}

.input-group-text i   {
      padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0; 
}
 




hr {
    background: #ffffff;
    width: 20%;
    /*margin: auto;*/
    align-items: start;
}

.nuarca-block {
    margin-top: 1rem;
    margin-bottom: .75rem;
    width: 4.5rem;
    height: 3px;
      background: linear-gradient(135deg, var(--mastColor), var(--accentColorzero));
}

.nuarca-block2 {
    margin-top: 1rem;
    margin-bottom: .75rem;
    width: 4.5rem;
    height: 3px;
      background: linear-gradient(135deg, var(--accentColor1), var(--accentColorzero));
}

.image_bumpin {
    position: relative;
    transform: translateX(-30px);
    max-width: 30%;
}

.image_bumpin2 {
    position: relative;
    transform: translateX(-30px);
    max-width: 20%;
}

.image_bumpin3 {
    position: relative;
    transform: translateX(+30px);
    max-width: 20%;
}

.image_bumpin4 {
    transform: translateX(-30px);
    max-width: 40%;
}

.image_bumpin5 {
    position: relative;
    transform: translateX(-30px);
    max-width: 40%;
}


.info_callout {
    width: 100%;
    margin: auto;
    text-align: center;
    text-transform: uppercase;
    color: #00aeef;
    background: transparent;
}

.info_callout img {
    width: 80%;
}

.info_callout a {
        color: #00aeef;
     text-decoration: none;
        transition: transform ease-in-out 3s;
}

.info_callout a:hover {
        background: rgba(0, 0, 0, .4);
}

.info_callout i {
color: #ffffff;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
}


.lead {
    text-transform: uppercase;
}

.loud {
    color: #6c757d;
}

.loud2 {
    color: #f68422;
}

.leadin {
    line-height: 1.5;
}

.navbar-brand {
    padding-top: var(--bs-navbar-brand-padding-y);
    padding-bottom: var(--bs-navbar-brand-padding-y);
    margin-right: var(--bs-navbar-brand-margin-end);
    font-size: var(--bs-navbar-brand-font-size);
    color: var(--bs-navbar-brand-color);
    text-decoration: none;
    white-space: nowrap;
}

.ul {
 font-size: var(--fs-p);   
}

ul.b-alt {
    list-style-type: disc;
    list-style-position: outside;
    padding-left: 2.4rem;
}

ul.c-alt {
    list-style-type: disc;
    list-style-position: outside;
    padding-left: 1.2rem;
}

.underline {
    text-decoration: underline;
}


.header-landing {
    margin: 1rem 1rem;
    display: grid;
    /* grid-template-rows: 2fr minmax(50px 250px) minmax(50px 250px) auto 1fr;*/
    grid-template-rows: 1fr 2fr 3fr 1fr 1fr;

    justify-items: center;
    height: 100vh;
}

.banner {
    /*position: relative;*/
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(rgba(41, 171, 226, 0.7),
            rgba(19, 82, 108, .9)), center center fixed;
    background-size: cover;
    background-position: top;
    /*    display: flow-root;*/
}

.flexbox {
    display: flex;
    align-items: center;
    justify-content: center;
}


.card-text i {
    color: #ffffff;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
}

.card-img-top {
    width: 100px;
}



#about_us img {
    width: 10rem;
}



.callout-text2 h1 {
    font-size: 3rem;
    background-repeat: no-repeat;
    background-position: fixed;
    background-size: cover;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}

#callout2 {
    background: #ffffff;
    background-size: cover;
}



#carousel {
    border-top: 5px #fff solid;
    border-bottom: 5px #fff solid;
       min-height: calc(15vh + 90px);

   
    /*  height: minmax(30vh 40vh);*/
}

#carousel img {
    object-position: top;
    width: 100vw;
    min-height: calc(15vh + 90px);
    max-height: 20vh;
    display: block;
    object-fit: cover;
}

#contact .fas {
    margin-bottom: 0rem;
    color: #ffffff;
}

#contact .fas:hover {
    opacity: 0.7;
}

#contact {}

#contact img {
    filter: drop-shadow(0 0 10px #006489);
    width: 75%;
}

#cover {
    padding-top: 1.5rem;
    padding-bottom: 1rem;
    margin-bottom: 0;
    width: 100vw;
    /*background-color: rgba(20, 71, 120, 0.5);*/
    /*background-repeat: no-repeat;
  background-position: center center;*/
    background-size: cover;
}

#cover .container {
    padding-left: 0px;
}

#create-section p {
    color: #58595b;
}

#create-section .fas {
    color: #00aeef;
}

#faq {
    border: #444;
}

#faq a {
    color: #fff;
    text-decoration: none;
}


/* footer */
.footer {
    /* background: #44546A;*/
    background: linear-gradient(rgba(0, 0, 0, 1),
            rgba(0, 0, 0, 1)), center center fixed;
    background-size: cover;
    background-position: top;
    color: white;
    font-size: 1rem;
    padding: 2.5rem 0;
}

.footer a {
    color: darkgrey;
}

.footer img {
    width: 16rem;
    float: left;
}

.footer-brand {
    filter: drop-shadow(0 0 4px #182229);
}

#home-heading {
    position: relative;
    min-height: 200px;
    background-attachment: fixed;
    background-repeat: no-repeat;
    text-align: center;
    color: #fff;
}

#livery_globalx {
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: 3px #00aeef solid;
}

.navbar .nav-link  {
    font-size: .8rem;
    text-transform: uppercase;
    padding-left: 1rem;
     padding-right: 1rem !important;
}

.smurfs  {
    font-size: .8rem;
    text-transform: uppercase;
    padding-left: 1rem;
      padding-right: 1rem !important;
}

.navbar .nav-item.active .nav-item.hover {
    background: rgba(0, 0, 0, .2);
   
}

#news img {
    width: 6rem;
}




.nucomply_assistant:nth-child(even) {
    padding: 0 5px;
    border-radius: 0px;
    background: linear-gradient(rgba(14, 35, 52, .7), rgba(14, 35, 52, .9), rgba(42, 107, 181, .9),
            rgba(57, 140, 201, .7)), right bottom fixed, left top fixed ;
    background-size: cover;
    background-position: top;
}

.nucomply_assistant:nth-child(odd) {
    padding: 0 5px;
    border-radius: 0px;
    background: linear-gradient(rgba(14, 35, 52, .7), rgba(14, 35, 52, .9), rgba(42, 107, 181, .9),
            rgba(57, 140, 201, .7)), right bottom fixed,  left top fixed ;
    background-size: cover;
    background-position: top;
}



.focus_area_nucomply {
     border-radius: 50% 50% 50% 50% / 50% 50% 50% 50%;
    background: var(--accentColorzero);
 /*  -webkit-box-reflect: below 0 linear-gradient (transparent, #fff5);*/
 transition: 
        transform 500ms ease-in-out, 
        background 500ms ease-in-out; 
}

.focus_area_nucomply:hover {
    background: var(--accentColor6);
        transform: scale(1.1);  
}

.nuarca_callout {
    padding: 0 5px;
    border-radius: 0px;

    background: linear-gradient(rgba(0, 0, 0, .9),
            rgba(0, 0, 0, .9)), center center fixed;
    background-size: cover;
    background-position: top;
}

.nuarca_callout img {
    width: 100%;

}

.nuarca_small img {
    width: 100px;
}

.nuarca_medium img {
    width: 100%;
}

#page-header {
    height: 200px;
    background-position: 0 -360px;
    background-attachment: fixed;
    color: #fff;
    border-bottom: 1px #eee solid;
    padding-top: 50px;
}

.secondary_banner {
    /*position: relative;*/
    width: 100%;
    min-height: 25vh;
    background: linear-gradient(rgba(41, 171, 226, 0.6),
            rgba(19, 82, 108, 1)), center center fixed;
    background-size: cover;
    background-position: top;
    /*    display: flow-root;*/
}

.success_stories img {
    width: 100%;
}

.success_stories:nth-child(odd) {
    padding: 0 5px;
    border-radius: 0px;
    background: linear-gradient(rgba(0, 0, 0, 1),
            rgba(0, 0, 0, .8)), left top fixed ;
    background-size: cover;
    background-position: top;
}

.success_stories:nth-child(even) {
    padding: 0 5px;
    border-radius: 0px;
 background: linear-gradient(rgba(0, 0, 0, 1),
            rgba(0, 0, 0, .5)), left top fixed ;
    background-size: cover;
    background-position: top;
}

#showcase .fas {
    margin-bottom: 2rem;
    color: #00aeef;
}

#showcase .fas:hover {
    opacity: 0.7;

}

#showcase p {
    color: #58595b;
}



#showcase2 .fas {
    margin-bottom: 2rem;
    color: #00aeef;
}

#showcase2 .fas:hover {
    opacity: 0.7;

}

#showcase2 p {
    color: #58595b;
}

#showcase2 img {
    filter: drop-shadow(0 0 20px #000000);
}

.technology_about img {
    width: 100%;
}

.timeline {
    background: linear-gradient(rgba(255, 255, 255, .5),
            rgba(255, 255, 255, .5));
    border-radius: 10px;
    padding: 5px;
    margin-top: 15px;
    color: #ffffff;
}

#value_prop_leadin {
    padding-top: calc(1.5vw + 5px);
        padding-bottom: calc(.75vw + 5px);
}








#value_prop {
    filter: drop-shadow(0 0 10px #000000);
}

.videoscreen {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 20px;
    color: white;
}

.video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: var(--primary-color) no-repeat center center/cover;
}

.video-container video {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.video-container:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
}

/* .content {
    z-index: 10;
} */




.flerm:nth-child(6) {
   background: var(--neonColor2);
}

/*END VIDEO PAGE*/

#video-play {
    position: relative;
    min-height: 200px;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: 0 -100px;
    text-align: center;
    color: #fff;
}

#video-play a {
    color: #fff;
}

/* MODALs */
.modal-content {
    background-color: rgba(0, 0, 0, .8);
    border-radius: 0rem;
}

.modal-header {
    border-radius: 0rem;

    background: var(--accentColor6);
}

.btn-nuarca {
    color: #fff!important;
    background-color: transparent !important;
    background-image: none !important;
    border-color: #f68422 !important;
}
/* Custom scrollbar styles for WebKit browsers */
::-webkit-scrollbar {
    width: 8px;
  }
  
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1); /* Very faint position marker */
    border-radius: 4px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.2); /* Slightly more visible on hover */
  }
  
  /* Custom scrollbar styles for Firefox */
  textarea {
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.1) transparent;
  }

@media (min-width: 576px) {
    .container {
        max-width: 97vw;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 90vw;
    }

}

@media (min-width: 992px) {
    .container {
        max-width: 80vw;
    }
    
    

}
    #value_prop_leadin {
    padding-top: 2rem;;
        padding-bottom: 0rem;;
}


@media (min-width: 1200px) {
    .container {
        max-width: 70vw;
    }

    .carousel .carousel-caption {
        width: 70vw;
    }
}

@media (min-width: 1500px) {
    .container {
        max-width: 60vw;
    }

    .carousel .carousel-caption {
        width: 60vw;

    }
}

@media (min-width: 1800px) {
    .container {
        max-width: 55vw !important;
    }

    .carousel .carousel-caption {

        width: 55vw;

    }
}