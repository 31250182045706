th {
    font-size: 1.2em;
    font-weight: 600;
    color: #faf8f8;
    padding-bottom: 10px;
    border-bottom: 1px solid #fff !important;
    min-width: 100%;
    max-width: 150%;

}

tr {
    padding-top: 10px;
    padding-bottom: 10px;
    height: 15px;
}

.table-container {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    overflow-x: auto;
    width: 100%;
}

.no-data {
    padding: 8px;
    text-align: center !important;
    margin-left: auto;
    margin-right: auto;
}

#change {
    width: 40% !important;
}

#regulator, #date, #jurisdiction, #impact {
    width: 10% !important;
}

#type {
    width: 15% !important;
}

#status {
    width: 5% !important;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .table-container {
        overflow-x: scroll;
    }

    th, td {
        white-space: nowrap;
    }

    #change {
        width: 20% !important;
    }
    
    #regulator, #date, #jurisdiction, #impact {
        width: 20% !important;
    }
    
    #type {
        width: 20% !important;
    }

}

.pulse {
    animation: pulse 1s infinite;
  }

.pointer {
    cursor: pointer;
}
  
  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }