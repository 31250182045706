/* chat.css */
@media screen and (max-width: 768px) {
  .markdown-content ul {
    margin-left: 20px; /* Adjust as needed */
  }
  .markdown-content ol {
    margin-left: 20px; /* Adjust as needed */
  }
}


@media screen and (min-width: 768px) {
.markdown-content ul {
  margin-left: 20px; /* Adjust as needed */
}
.markdown-content ol {
  margin-left: 40px; /* Adjust as needed */
}
}
